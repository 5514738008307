const PexelModal = ({ show, onClose, content }) => {
    if (!show) {
        return null;
    }
    console.log(content, typeof content);
    content.map((item, index) => {
        console.log(item.image);
        item.pixabayVideos.map((video, videoIndex) => {
            // console.log(video.video_url);
        });
    });


    return (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pixabay Result</h5>
                        <button type="button" className="close" onClick={onClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-body row">
                            {content.map((item, index) => (
                                <div key={index} className="content-item mt-3 mr-0 col-md-6">
                                    <div className="rounded ">
                                        <h1 className="content-title mb-2"><strong>{item.image}</strong></h1>
                                        <div className="video-list mt-3">
                                            {item.pixabayVideos.length>0 ? item.pixabayVideos.map((video, videoIndex) => (
                                                <div key={videoIndex} className="video-item mb-4 p-3 border rounded shadow-sm">

                                                    <p><b>Video URL:</b> <a href={video.video_url} target="_blank" rel="noopener noreferrer">{video.video_url}</a></p>
                                                    <p><b>Page URL:</b> <a href={video.url} target="_blank" rel="noopener noreferrer">{video.url}</a></p>
                                                    <p><b>CC:</b> {video.cc}</p>

                                                </div>
                                            )):<>No Videos Selected</>}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PexelModal;