// import React, { useState, useEffect } from 'react';

// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import VisualPathComponent from './VisualPathComponent';
// import './videomodal.css';
// const VideoModal = () => {
//   const { id } = useParams();
//   console.log(id);
//   const [videoPath, setVideoPath] = useState(null);
//   const [storyboard, setStoryBoard] = useState({});

//   const BASE_URL=process.env.REACT_APP_API_KEY;
//   useEffect(() => {
//     const fetchVideo = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/getsinglefile/${id}`, {
//           responseType: 'blob',
//         });
//         const videoBlob = response.data;
//         setVideoPath(URL.createObjectURL(videoBlob));
//       } catch (error) {
//         console.error('Error fetching video:', error);
//         // Handle error if needed
//       }
//     };

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/getsingledata/${id}`)
//         const videodata = response.data;
//         // console.log(videodata);
//         setStoryBoard(videodata);
//       }
//       catch (err) {
//         console.error('Error fetching video:', err);
//       }
//     }

//     fetchData()
//     fetchVideo();

//   }, []);

//   return (
//     <>
//   {videoPath && storyboard ? (
//     <div className="container-fluid m-3 mt-5">
//        <h3 className="mb-4">User Query: {storyboard?.query}</h3>

//            <h3 className="mb-3">Video Title: {storyboard.storyboard?.videoTitle}</h3>
//       <div className="row">
//         <div className="col-md-8">

//             <hr></hr>
//           <video controls style={{ width: '100%' }}>
//             <source src={videoPath} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//         <div className="col-md-4">

//           <div className="visual-path-container" style={{ backgroundColor: '#EEEEEE', padding: '20px', borderRadius: '10px', marginBottom: '20px', height: '650px', overflowY: 'auto' }}>
//           <h1 style={{textAlign:'center'}}>Storyboard</h1>
//           <hr></hr>
//           {storyboard.storyboard?.category &&
//           <p><b>Category: {storyboard.storyboard?.category}</b></p>}
//             {storyboard?.visualPaths?.map((visualPath, index) => (
//               <VisualPathComponent key={index} visualPath={visualPath} index={index} />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div className="container-loader">
//   <span></span>
//   <span></span>
//   <span></span>
//   <span></span>
// </div>
//   )}
// </>

//   );
// };

// export default VideoModal;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import VisualPathComponent from './VisualPathComponent';
import './videomodal.css';
import PexelModal from './PexelModal';

const VideoModal = () => {
  const { id } = useParams();
  const [videoPath, setVideoPath] = useState(null);
  const [storyboard, setStoryBoard] = useState({});
  const [ogstoryboard, setOgStoryBoard] = useState({});
  const [editing, setEditing] = useState(false); // State to track editing mode
  const [editedData, setEditedData] = useState(''); // State to store edited data
  const [error, setError] = useState(true);
  const [editcall, setEditCall] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getsingledata/${id}`);
      const videodata = response.data;

      setStoryBoard(videodata);
      setOgStoryBoard(videodata.storyboard);
      setEditedData(JSON.stringify(videodata.storyboard, null, 2)); // Set initial value for editing
      // After fetching data, fetch video
      fetchVideo();
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };
  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getsinglefile/${id}`, {
        responseType: 'blob',
      });
      const videoBlob = response.data;
      setVideoPath(URL.createObjectURL(videoBlob));
    } catch (error) {
      console.error('Error fetching video:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, BASE_URL]); // Ensure useEffect runs when id or BASE_URL changes

  // Handler to toggle editing mode
  const toggleEditing = () => {
    setEditing(!editing);
  };

  // Handler to update edited data
  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    try {
      const updatedStoryboard = JSON.parse(newValue);
      const originalStoryboard = ogstoryboard;

      setEditedData(newValue);

      const updatedKeys = Object.keys(updatedStoryboard);


      const topLevelUpdatedKeys = Object.keys(updatedStoryboard);
      const topLevelOriginalKeys = Object.keys(originalStoryboard);
      const topLevelKeysChanged = !topLevelUpdatedKeys.every(key => topLevelOriginalKeys.includes(key));

      if (topLevelKeysChanged) {
        setError('Cannot change keys');
        return;
      }
      else {
        setError(null);
      }

      const updatedSections = updatedStoryboard.sections;
      const originalSections = originalStoryboard.sections;

      for (let i = 0; i < updatedSections.length; i++) {
        const updatedSection = updatedSections[i];
        const originalSection = originalSections[i];

        const updatedSectionKeys = Object.keys(updatedSection);
        const originalSectionKeys = Object.keys(originalSection);
        const sectionKeysChanged = !updatedSectionKeys.every(key => originalSectionKeys.includes(key));

        if (sectionKeysChanged) {
          setError('Cannot change keys inside sections');
          return;
        }
        else {
          setError(null);
        }

        const updatedVisuals = updatedSection.visuals;
        const originalVisuals = originalSection.visuals;

        for (let j = 0; j < updatedVisuals.length; j++) {
          const updatedVisualKeys = Object.keys(updatedVisuals[j]);
          const originalVisualKeys = Object.keys(originalVisuals[j]);
          const visualsKeysChanged = !updatedVisualKeys.every(key => originalVisualKeys.includes(key));

          if (visualsKeysChanged) {
            setError('Cannot change keys inside visuals');
            return;
          }
          else {
            setError(null);
          }
        }
      }



      // setError(null);
      // setEditedData(newValue);

    } catch (error) {
      console.log(error.message);
      setError('Invalid JSON structure');
      setEditedData(newValue);
    }
  };

  // Handler to save edited data
  const saveEditedData = async (id) => {
    try {
      console.log(id, 'hjbgfcvh');
      const updatedStoryboard = JSON.parse(editedData);
      setEditCall(false);


      const originalKeys = Object.keys(editedData);
      const updatedKeys = Object.keys(updatedStoryboard);

      const addedKeys = updatedKeys.filter(key => !originalKeys.includes(key));
      const removedKeys = originalKeys.filter(key => !updatedKeys.includes(key));
      const modifiedKeys = originalKeys.filter(key => updatedKeys.includes(key) && JSON.stringify(editedData[key]) !== JSON.stringify(updatedStoryboard[key]));

      if (addedKeys.length > 0 || removedKeys.length > 0 || modifiedKeys.length > 0) {
        setError(`Sorry... You can't change keys`);
      }

      // /await updateVideo(id, updatedStoryboard)

      setStoryBoard((prevStoryboard) => ({
        ...prevStoryboard,
        storyboard: updatedStoryboard,
      }));
      setEditing(false); // Exit editing mode after saving

      const apiUrl = `${process.env.REACT_APP_API_KEY}/api/updatevideo/${id}`;

      // Prepare the request options
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ storyboard: updatedStoryboard })
      };

      try {

        const response = await fetch(apiUrl, requestOptions);

        // Check if the request was successful
        if (!response.ok) {
          Navigate('/');
          throw new Error('Failed to update video.');
        }
        if (response.ok) {
          setEditCall(true);
        }
        // Parse the response as JSON
        const responseData = await response.json();
        return responseData; // Return the response data
      } catch (error) {
        console.error('Error updating video:', error.message);
        throw error; // Re-throw the error to handle it outside the function
      }


    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };



  return (
    <>
      {editcall && storyboard ? (
        <div className="container-fluid m-3 mt-5">
          <h4 className="mb-4">User Query: {storyboard?.query}</h4>
          <h4 className="mb-3">Video Title: {storyboard.storyboard?.videoTitle}</h4>
          <div className="row">
            <div className="col-md-8">
              <hr></hr>
              {videoPath ? (
                <video controls style={{ width: '100%', height: '80%' }}>
                  <source src={videoPath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div className="container-loader">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="visual-path-container" style={{ backgroundColor: '#EEEEEE', padding: '20px', borderRadius: '10px', marginBottom: '20px', height: '650px', overflowY: 'auto' }}>
                <h3 style={{ textAlign: 'center' }}>Storyboard</h3>
                <hr></hr>
                {(storyboard?.userCategory) ? <><b>Selected Category:</b> {storyboard?.userCategory}</> : storyboard.storyboard?.category && <p><b>selectedCategory: {storyboard.storyboard?.category}</b></p>}
                <div className="keywords">
                  {(storyboard.searchparm?.category || storyboard.searchparm?.keywords || storyboard.searchparm?.visuals) && <b>Selected Param: </b>}
                  {storyboard.searchparm?.visuals && <p> Visuals</p>}{storyboard.searchparm?.visuals && ', '}
                  {storyboard.searchparm?.keywords && <p> Keywords</p>}{storyboard.searchparm?.keywords && storyboard.searchparm?.category && ', '}
                  {storyboard.searchparm?.category && <p> Category</p>}
                </div>
                {(storyboard?.videosApi) && <p><b>Libraries</b>: {storyboard?.videosApi.Pexel && <>Pexel,</>} {storyboard?.videosApi.Pixabay && <>Pixabay,</>} {storyboard?.videosApi['Sify Library'] && <>Sify Library</>}</p>}.
                {editing ? (
                  <div>
                    <textarea
                      value={editedData}
                      onChange={handleTextareaChange}
                      style={{ width: '100%', height: '400px', marginBottom: '10px' }}
                    ></textarea>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div style={{ textAlign: 'center' }}>
                      <button className="btn btn-primary" onClick={() => { saveEditedData(storyboard._id) }} disabled={error ? true : false}>Save</button>
                      <button className="btn btn-secondary ml-2" onClick={toggleEditing}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {storyboard.storyboard?.category && <p><b>LLM-Category: {storyboard.storyboard?.category}</b></p>}
                    {storyboard?.visualPaths?.map((visualPath, index) => (
                      <VisualPathComponent key={index} visualPath={visualPath} index={index} />
                    ))}

                    {storyboard?.storyboard?.scenes && <><textarea
                      value={editedData}
                      disabled={true}
                      style={{ width: '100%', height: '800px', marginBottom: '10px' }}
                    ></textarea></>
                    }

                  </div>
                )}

                <div className='row'>


                  {storyboard?.videosApi?.Pixabay && <div style={{ textAlign: 'center', marginTop: '10px' }} >

                    <button className="btn btn-info" onClick={toggleModal}>PixabayAPI Results</button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <PexelModal show={isModalOpen} onClose={toggleModal} content={storyboard?.visualPaths} />
        </div>
      ) : (
        <div className="container-loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </>
  );
};

export default VideoModal;

