import React from 'react';

const VisualPathComponent = ({ visualPath, index }) => {
    const { image, narration, display_text, videoUrls, keywords, norelevant } = visualPath;

    return (
        <div className="visual-path" >

            <div className="row">

                <p><b>{index + 1}.)  Display Text:</b> {display_text}</p>


            </div>
            <div >
                <><h5>Narration:</h5>{narration}</>
                <h5><b>keywords: </b>{keywords}</h5>
                <h3><b>Visual</b>: {image}</h3>

                <ul className="">

                    {norelevant ? <>{norelevant.map((videoUrl, index) => (
                        <li key={index} >
                            <a
                                href={videoUrl.url}
                                className="text-decoration-none"
                                style={{ color: videoUrl.isFiller === 1 ? 'red' : '' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {videoUrl.url}{videoUrl.isFiller === 1 ? ' (Filler)' : ''}
                            </a>
                        </li>
                    ))}</> : videoUrls.map((videoUrl, index) => (
                        <li key={index}><a href={videoUrl} className="text-decoration-none">{videoUrl}</a></li>
                    ))}
                </ul>
            </div>
            <hr></hr>
        </div>
    );
};

export default VisualPathComponent;
