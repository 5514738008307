import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


const StoryboardModal = ({ show, handleClose, version, type, fetchData }) => {
    const BASE_URL = process.env.REACT_APP_API_KEY;


    const handlePasswordSubmit = async () => {
        const password = prompt(`Enter password to delete this version ${version?.version}`);
        if (password == '') {
            alert(`password can't be empty`)
        } else {

            try {
                const response = await axios.post(`${BASE_URL}/api/deleteversion`, {
                    type,
                    version: version.version,
                    password,
                    email: "coesify@sifycorp.com"
                });
                if (response.data.status === 'success') {
                    alert('Version deleted successfully');
                    fetchData();
                    handleClose();
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                console.error(error);
                alert('Error deleting version');
            }

        }
    };

    const handleCopyToClipboard = () => {
        if (version) {
            navigator.clipboard.writeText(version.value)
                .then(() => {
                    alert('Text copied to clipboard!');
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>

                    <Modal.Title>Storyboard Version - {version?.version}</Modal.Title>

                    <button
                        className="btn btn-danger"

                        onClick={handlePasswordSubmit}
                        style={{ cursor: 'pointer' , marginLeft: '2%' }}
                    >
                        Delete this version
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleCopyToClipboard}
                        style={{ cursor: 'pointer', marginLeft: '2%' }}
                    >
                        Copy to Clipboard
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {version ? <textarea style={{ resize: " none" }}
                        value={version.value}
                        className='modal_textarea'
                        placeholder="Type your storyboard generation prompt here..."
                        rows={24}

                        disabled={true}
                        spellCheck={false}
                    /> : <p>No version selected.</p>}
                </Modal.Body>
            </Modal>


        </>
    );
};

export default StoryboardModal;

