import './App.css';
import { useState, useEffect, useMemo } from 'react';
import Image from './components/Image';
import { Routes, Route, useNavigate } from "react-router-dom";
import VideoModal from './components/VideoModal';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Prompt from './components/Prompt';
import EditModal from './components/EditModal';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
function App() {
    // toast.configure();
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const LLAMA_INDEX_SEARCH = `${process.env.REACT_APP_API_KEY}/api/getlist`;
    const LLAMA_INDEX_SEARCH2 = `${process.env.REACT_APP_API_KEY}/api/query `;

    const [isValid, setIsValid] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [show, setShow] = useState(true);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const [selectedItems, setSelectedItems] = useState({
        visuals: true,
        keywords: false,
        category: true
    });

    const [VideosApiSelection, setVideosApiSelection] = useState({
        Pixabay: false,
        Pexel: true,
        "Sify Library": true
    });
    const checkIfAnyTrue = (obj) => {
        return Object.values(obj).some(value => value === true);
    };
    const handlevideoApiChange = (itemName) => {
        setVideosApiSelection({
            ...VideosApiSelection,
            [itemName]: !VideosApiSelection[itemName]
        });
    };

    const handleCheckboxChange = (itemName) => {
        setSelectedItems({
            ...selectedItems,
            [itemName]: !selectedItems[itemName]
        });
    };


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const checkvideos = checkIfAnyTrue(VideosApiSelection);
            if (!checkvideos) {
                alert("Please select atleast one video API");
            }
            else {
                setInputDisabled(true);
                setButtonDisabled(true);
                setLoading(true);
                event.preventDefault();
                window.scrollTo({ top: 0, behavior: 'auto' });
                sendData();
            }
            if (inputValue === "") {
                setErrorMessage(true);
                alert("Promlt can't be empty")
            }

        }
    };

    const generateCall = async () => {
        const checkvideos = checkIfAnyTrue(VideosApiSelection);
        if (inputValue === "") {
            setErrorMessage(true);
            alert("Prompt can't be empty")
        }
        if (!checkvideos) {
            alert("Please select atleast one video API");
        }
        else {
            setInputDisabled(true);
            setButtonDisabled(true);
            setLoading(true);
            sendData();
        }

    };

    const sendData = async () => {

        if (inputValue === "") {
            setErrorMessage(true);
            alert("Prompt can't be empty")
        } else {
            try {
                setLoading(true);

                setTimeout(userSession, 200)
                const intervalId = setInterval(userSession, 5000);

                const response = await fetch(LLAMA_INDEX_SEARCH2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNzIxODk4NzYzLCJleHAiOjE3MjE5ODUxNjN9.NYlfIE-allXx5Ub9WmMpXxaZFpW-4mOa93mbcBPOY-0'
                    },
                    body: JSON.stringify({ query: inputValue, searchparam: selectedItems, selectedCategory: selectedCategory, videosApi: VideosApiSelection }),
                });
                console.log(response);
                if (response.status == 400) {
                    const errorData = await response.json();
                    console.error("Error:", errorData.message);
                    setInputDisabled(false);
                    setButtonDisabled(false);
                    toast.error(`${errorData.message} please try again after sometime`, {

                    });
                }
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error("Error:", errorData.message);

                    setInputDisabled(false);
                    setButtonDisabled(false);
                    throw new Error('Network response was not ok');
                }


                setInputValue('');
                setLoading(false);
                setInputDisabled(false);
                setButtonDisabled(false);
                const output = await response.json();
                console.log(output)
                clearInterval(intervalId);

                navigate(`/videodisplay/${output[0]._id}`);
                await userSession();
            } catch (error) {
                console.error('Error sending data:', error.message);
                setLoading(false);
            }
        }
    };

    const listData = async (pass) => {
        try {

            const response = await fetch(LLAMA_INDEX_SEARCH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: pass, email: "coesify@sifycorp.com" })
            });
            console.log(response, "---res");
            if (!response.ok) {
                // setLoading(false);
                handlePrompt();

                // throw new Error('Network response was not ok');
            }

            if (response.status == 200) {
                localStorage.setItem('userData', JSON.stringify({ user: 'Admin123@', Time: Date.now() }));
                const output = await response.json();
                setIsValid(false);
                setResult(output);
                // setLoading(false);
            } else if (response.status == 403) {
                handlePrompt();
            }



        } catch (error) {
            console.log(error);
            console.error('Error sending data:', error.message);
            setLoading(false);
        }
    };

    const userSession = async () => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            const parsedData = JSON.parse(userData);
            console.log(parsedData);
            if (parsedData?.Time) {
                const currentTime = Date.now();
                const differenceInMillis = currentTime - parsedData?.Time;

                const differenceInMinutes = differenceInMillis / (1000 * 60);


                if (differenceInMinutes > 59) {
                    handlePrompt();
                }
                else {
                    listData(parsedData.user);
                }
            }

        }
        else {
            handlePrompt();
        }

    }
    useEffect(() => {


        userSession();

        const handleScroll = () => {
            const scrollPercentage = (window.scrollY / document.body.scrollHeight) * 100;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError(''); // Clear error when user types
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (password.length < 6) {
            setError('Password must be at least 6 characters long.');
        } else {

            listData(password);
            console.log('Password:', password);

            handleClose();
        }
    };

    const handlePrompt = async () => {

        const userResponse = prompt('Please enter your password:');
        setPassword(userResponse);
        listData(userResponse)

    }



    return (
        <>

            <div id="content">
                <Routes>
                    <Route exact path="/" element={
                        isValid ?
                            <div >
                                {/* <Button variant="primary" onClick={handleShow}>
                                    Password Modal
                                </Button>
                                <Modal show={show} onHide={handleClose} fullscreen='true'                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {error && <Alert variant="danger">{error}</Alert>}
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="formPassword">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Enter your password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handleSubmit}>
                                            submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal> */}
                                {loading && <div className="container-loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>}
                            </div> : <>
                                <Navbar inputValue={inputValue} handleInputChange={handleInputChange} handleKeyDown={handleKeyDown} loading={loading} errorMessage={errorMessage} buttonDisabled={buttonDisabled} inputDisabled={inputDisabled} generateCall={generateCall} selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} selectedCategory={selectedCategory} handleCategoryChange={handleCategoryChange} handlevideoApiChange={handlevideoApiChange} VideosApiSelection={VideosApiSelection} />
                                <Image url={BASE_URL} results={result} listData={userSession} />
                            </>} />
                    <Route path='edit/videodisplay/:id' element={<EditModal selectedItems={selectedItems} handleCheckboxChange={handleCheckboxChange} handlevideoApiChange={handlevideoApiChange} VideosApiSelection={VideosApiSelection} />} />
                    <Route path="/videodisplay/:id" element={<VideoModal />} />
                    <Route path='/promptmaker' element={<Prompt />} />
                </Routes>

            </div>
            {/* <ToastContainer  autoClose={60000}/> */}
        </>

    );
}



const Navbar = ({ inputValue, handleInputChange, handleKeyDown, loading, errorMessage, buttonDisabled, inputDisabled, generateCall, selectedItems, handleCheckboxChange, selectedCategory, handleCategoryChange, handlevideoApiChange, VideosApiSelection }) => {

    const categories = [
        "Entertainment",
        "Education",
        "Music",
        "Healthcare",
        "Science",
        "Technology",
        "Sports",
        "Cooking & Food",
        "Travel & Adventure",
        "Lifestyle",
        "Health & Wellness",
        "DIY & Crafts",
        "Fashion & Beauty",
        "Gaming",
        "Comedy",
        "News & Politics",
        "Business & Finance",
        "Art & Creativity",
        "History",
        "Nature & Environment",
        "Documentary",
        "Industry",
        "Cars & Automobiles"
    ];

    const values = ["category", "visuals", "keywords"];

    const videosApi = ["Sify Library", "Pexel", "Pixabay"]



    return (
        <div id='navbar'>
            <div id='title_div'>
                <header className="row py-3" id='header'>
                    <a href="/" id='title' className="text-dark text-decoration-none text-center">Video Authoring Tool</a>
                </header>
            </div>
            <div className='row justify-content-start align-items-center ' id='search_items'>
                <div className='col-lg-6 offset-lg-2 ' id='search_div' >
                    <input
                        id={errorMessage ? 'searchbar_error' : 'searchbar'}
                        className='form-control shadow-sm bg-body-tertiary'
                        type='text'
                        placeholder={errorMessage ? "Search input should not be empty" : "Enter a prompt to create video..."}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        disabled={inputDisabled}
                    />
                </div>
                <div className='col-lg-2 mt-2' >
                    <button
                        style={{ width: '100%' }}
                        className="btn btn-primary fs-7 mb-2 p-2" id="search_btn" type="submit" onClick={generateCall} disabled={buttonDisabled}>Create Video</button>
                </div>
            </div>

            <div className="row justify-content-center align-items-center mt-3">

                <div className="dropdown-center col-md-2 mb-2 ">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        AI Search Criteria
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {values.map((value, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input text-center"
                                    type="checkbox"
                                    id={`${value}Checkbox`}
                                    checked={selectedItems[value]}
                                    disabled={value == 'category' ? true : false}
                                    onChange={() => handleCheckboxChange(value)}
                                />
                                <label className="form-check-label" htmlFor={`${value}Checkbox`}>
                                    {value.charAt(0).toUpperCase() + value.slice(1)}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="dropdown-center col-md-3 mb-2">
                    <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Libraries
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        {videosApi.map((value, index) => (
                            <div className="form-check" key={index}>
                                <input
                                    className="form-check-input text-center"
                                    type="checkbox"
                                    id={`${value}Checkbox`}
                                    checked={VideosApiSelection[value]}
                                    onChange={() => handlevideoApiChange(value)}
                                />
                                <label className="form-check-label" htmlFor={`${value}Checkbox`}>
                                    {value}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="dropdown-center col-md-3 mb-2">
                    <select
                        className="form-select w-100 text-center"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        <option value="" className="text-center">Select Category</option>
                        {categories.map((category) => (
                            <option key={category} value={category} className="text-center">
                                {category}
                            </option>
                        ))}
                    </select>
                </div>



            </div>

            {loading && (
                <div className="processing-animation">
                    Processing...
                </div>
            )}
        </div>
    );
};

export default App;
