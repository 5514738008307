import "./Image.css";
import React, { useState } from "react";
import { useEffect } from "react";
import VideoModal from "./VideoModal";
import { useNavigate } from "react-router-dom";
// import { Navbar } from "react-bootstrap";
function Image({ url, results, listData }) {
    console.log(results[0]);
    const [showModal, setShowModal] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const navigate = useNavigate();

    // const handleClick = (article) => {
    //     console.log(article);
    //     let videoUrl = "http://localhost:8000/api/getsinglefile/" + article._id;

    //     axios
    //         .get(videoUrl, {
    //             responseType: "blob", // Request for Blob response
    //         })
    //         .then((response) => {
    //             setSelectedVideo(response.data); // Store the Blob data in state
    //             setShowModal(true); // Show the modal
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching video:", error);
    //             // Handle error if needed
    //         });
    // };


    const handleClick = (article) => {

        navigate(`/videodisplay/${article._id}`);

    };

    const handleClose = () => {
        setShowModal(false);
    };
    const [open, setOpen] = useState(false);

    const [display, setDisplay] = useState([]);


    const BASE_URL = process.env.REACT_APP_API_KEY;
    function handleDelete(articleId) {
        let videoUrl = `${BASE_URL}/api/deletevideo/` + articleId;
        const password = prompt("Enter password:");


        fetch(videoUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: password,
                email: "coesify@sifycorp.com"
            })
        })
            .then(response => {
                if (response.ok) {
                    alert("Article deleted successfully");
                    listData();
                } else {
                    alert("Failed to delete article");
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert("Failed to delete article");
            });

    }


    // Function to handle mouse enter event
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };



    useEffect(() => {
        console.log(results);
    }, [display]);

    return (
        <>


            <div id="image_grid" className="container-fluid m-3 mt-5">
                {results.map((article, i) => (
                    <div id='article_div' className={`container-fluid ${article.status.toLowerCase() !== "completed" ? "greyed-out" : ""}`} key={i} >
                        <div className="bg-light rounded m-3">
                            <div onClick={() => article.status.toLowerCase() === "completed" && handleClick(article)}>
                                <div className='p-5'>
                                    <div>
                                        <div id='article_headline'>
                                            <h5
                                            >{article.storyboard?.videoTitle || (article.query.charAt(0).toUpperCase() + article.query.slice(1))}</h5>
                                        </div>
                                        <div id='score_read'>
                                            {article.status.toLowerCase() === "completed" ? null : (
                                                <>
                                                    <p><b>Status</b>: InProgress...</p>

                                                    <p className="inline" style={{ marginTop: "10px" }}>Stage: {article.status}</p>

                                                </>
                                            )}

                                            {article.userCategory ? <p><b>Category:</b> {article.userCategory} </p> : article.storyboard?.category && <p><b>Category:</b> {article.storyboard?.category} </p>}
                                            <div className="keywords">
                                                {(article.searchparm?.category || article.searchparm?.keywords || article.searchparm?.visuals) && <b>Selected Param: </b>}

                                                {article.searchparm?.visuals && <p> Visuals</p>}{article.searchparm?.visuals && ', '}
                                                {article.searchparm?.keywords && <p> Keywords</p>}{article.searchparm?.keywords && article.searchparm?.category && ', '}
                                                {article.searchparm?.category && <p> Category</p>}
                                            </div>
                                            {article?.Error && <p style={{ color: "red" }}><b >Error:</b> {article?.Error}</p>}

                                        </div>
                                    </div>
                                    <button className="btn btn-danger float-right" onClick={(e) => { e.stopPropagation(); handleDelete(article._id); }}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>

                            </div>

                        </div>
                        {/* <button className="btn btn-danger float-right" onClick={() => handleDelete(article._id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button> */}

                    </div>
                ))}
            </div>
            {showModal && <VideoModal
                show={showModal}
                handleClose={handleClose}
                videoPath={selectedVideo}
            />}


        </>
    );
}

export default Image;
