import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import VisualPathComponent from './VisualPathComponent';
// import { setTimeout } from 'timers/promises';
// import './VideoModal.css';

const EditModal = ({ selectedItems, handleCheckboxChange, handlevideoApiChange, VideosApiSelection }) => {
  const { id } = useParams();
  const [videoPath, setVideoPath] = useState(null);
  const [storyboard, setStoryBoard] = useState({});
  const [ogstoryboard, setOgStoryBoard] = useState({});
  const [editing, setEditing] = useState(false);
  const [editedData, setEditedData] = useState('');
  const [error, setError] = useState(true);
  const [editcall, setEditCall] = useState(true);
  const BASE_URL = process.env.REACT_APP_API_KEY;

  const values = ["category", "visuals", "keywords"];

  const videosApi = ["Sify Library", "Pexel", "Pixabay"]


  const fetchData = async () => {
    try {

      const response = await axios.get(`${BASE_URL}/api/getsingledata/${id}`);
      const videodata = response.data;
      setStoryBoard(videodata);
      setOgStoryBoard(videodata.storyboard);
      setEditedData(JSON.stringify(videodata.storyboard, null, 2)); // Set initial value for editing
      // After fetching data, fetch video
      fetchVideo();
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };
  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getsinglefile/${id}`, {
        responseType: 'blob',
      });
      const videoBlob = response.data;
      setVideoPath(URL.createObjectURL(videoBlob));
    } catch (error) {
      console.error('Error fetching video:', error);
    }
  };

  useEffect(() => {



    fetchData();
  }, [id, BASE_URL]); // Ensure useEffect runs when id or BASE_URL changes

  // Handler to toggle editing mode
  const toggleEditing = () => {
    // const password = prompt("Enter password:");
    // if (password === 'Admin123@') {
    setEditing(!editing);
    // } else {
    //   alert('Invalid password...!');
    // }
  };

  // Handler to update edited data
  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    try {
      const updatedStoryboard = JSON.parse(newValue);
      const originalStoryboard = ogstoryboard;

      setEditedData(newValue);

      const updatedKeys = Object.keys(updatedStoryboard);


      const topLevelUpdatedKeys = Object.keys(updatedStoryboard);
      const topLevelOriginalKeys = Object.keys(originalStoryboard);
      const topLevelKeysChanged = !topLevelUpdatedKeys.every(key => topLevelOriginalKeys.includes(key));

      if (topLevelKeysChanged) {
        setError('Cannot change keys');
        return;
      }
      else {
        setError(null);
      }

      const updatedSections = updatedStoryboard.sections;
      const originalSections = originalStoryboard.sections;

      for (let i = 0; i < updatedSections.length; i++) {
        const updatedSection = updatedSections[i];
        const originalSection = originalSections[i];

        const updatedSectionKeys = Object.keys(updatedSection);
        const originalSectionKeys = Object.keys(originalSection);
        const sectionKeysChanged = !updatedSectionKeys.every(key => originalSectionKeys.includes(key));

        if (sectionKeysChanged) {
          setError('Cannot change keys inside sections');
          return;
        }
        else {
          setError(null);
        }

        const updatedVisuals = updatedSection.visuals;
        const originalVisuals = originalSection.visuals;

        for (let j = 0; j < updatedVisuals.length; j++) {
          const updatedVisualKeys = Object.keys(updatedVisuals[j]);
          const originalVisualKeys = Object.keys(originalVisuals[j]);
          const visualsKeysChanged = !updatedVisualKeys.every(key => originalVisualKeys.includes(key));

          if (visualsKeysChanged) {
            setError('Cannot change keys inside visuals');
            return;
          }
          else {
            setError(null);
          }
        }
      }



      // setError(null);
      // setEditedData(newValue);

    } catch (error) {
      console.log(error.message);
      setError('Invalid JSON structure');
      setEditedData(newValue);
    }
  };

  // Handler to save edited data
  const saveEditedData = async (id) => {
    try {
      console.log(id, 'hjbgfcvh');
      const updatedStoryboard = JSON.parse(editedData);
      setEditCall(false);


      const originalKeys = Object.keys(editedData);
      const updatedKeys = Object.keys(updatedStoryboard);

      const addedKeys = updatedKeys.filter(key => !originalKeys.includes(key));
      const removedKeys = originalKeys.filter(key => !updatedKeys.includes(key));
      const modifiedKeys = originalKeys.filter(key => updatedKeys.includes(key) && JSON.stringify(editedData[key]) !== JSON.stringify(updatedStoryboard[key]));

      if (addedKeys.length > 0 || removedKeys.length > 0 || modifiedKeys.length > 0) {
        setError(`Sorry... You can't change keys`);
      }

      // /await updateVideo(id, updatedStoryboard)

      setStoryBoard((prevStoryboard) => ({
        ...prevStoryboard,
        storyboard: updatedStoryboard,
      }));
      setEditing(false); // Exit editing mode after saving

      const password = prompt('Enter password to update the video');
      if (password == '') {
        alert("password can't be empty")
      }
      else {
        const apiUrl = `${process.env.REACT_APP_API_KEY}/api/updatevideo/${id}`;

        // Prepare the request options
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ storyboard: updatedStoryboard, selectedItems: selectedItems, VideosApiSelection: VideosApiSelection, password: password, email: "coesify@sifycorp.com" })
        };

        try {

          const response = await fetch(apiUrl, requestOptions);

          // Check if the request was successful
          // if (!response.ok) {
          //   Navigate('/');
          //   // throw new Error('Failed to update video.');
          // }
          if (!response.ok) {
            if (response.status == 403) {
              alert("Password is incorrect");
              setEditCall(true);
            }
            else if (response.status == 401) {
              alert("You are not authorized to update this video");
            }

          }
          if (response.ok) {
            await fetchData().then(() => {
            });
            setTimeout(() => {
              setEditCall(true);
            }, 1000);

          }
          // Parse the response as JSON
          const responseData = await response.json();
          return responseData; // Return the response data
        } catch (error) {
          console.error('Error updating video:', error.message);
          throw error; // Re-throw the error to handle it outside the function
        }
      }



    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  async function updateVideo(id, updateFields) {
    // Construct the URL for the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_KEY}/api/updatevideo/${id}`;

    // Prepare the request options
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ storyboard: updateFields })
    };

    try {

      const response = await fetch(apiUrl, requestOptions);

      // Check if the request was successful
      if (!response.ok) {
        Navigate('/');
        throw new Error('Failed to update video.');
      }
      if (response.ok) {
        setEditCall(true);
      }
      // Parse the response as JSON
      const responseData = await response.json();
      return responseData; // Return the response data
    } catch (error) {
      console.error('Error updating video:', error.message);
      throw error; // Re-throw the error to handle it outside the function
    }
  }

  return (
    <>
      {editcall && storyboard ? (
        <div className="container-fluid m-3 mt-5">
          <h4 className="mb-4">User Query: {storyboard?.query}</h4>
          <h4 className="mb-3">Video Title: {storyboard.storyboard?.videoTitle}</h4>
          <div className="row">
            <div className="col-md-8">
              <hr></hr>
              {videoPath ? <video controls style={{ width: '100%', height: '80%' }}>
                <source src={videoPath} type="video/mp4" />
                Your browser does not support the video tag.
              </video> : <div className="container-loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>}
            </div>
            <div className="col-md-4">
              <div className="visual-path-container" style={{ backgroundColor: '#EEEEEE', padding: '20px', borderRadius: '10px', marginBottom: '20px', height: '650px', overflowY: 'auto' }}>
                <h3 style={{ textAlign: 'center' }}>Storyboard</h3>
                <hr></hr>
                {(storyboard?.userCategory) ? <><b>Selected Category:</b> {storyboard?.userCategory}</> : storyboard.storyboard?.category &&
                  <p><b>selectedCategory: {storyboard.storyboard?.category}</b></p>}
                <div className="keywords">

                  {(storyboard.searchparm?.category || storyboard.searchparm?.keywords || storyboard.searchparm?.visuals) && <b>Selected Param: </b>}

                  {storyboard.searchparm?.visuals && <p> Visuals</p>}{storyboard.searchparm?.visuals && ', '}
                  {storyboard.searchparm?.keywords && <p> Keywords</p>}{storyboard.searchparm?.keywords && storyboard.searchparm?.category && ', '}
                  {storyboard.searchparm?.category && <p> Category</p>}
                </div>
                {(storyboard?.videosApi) && <p><b>Libraries</b>: {storyboard?.videosApi.Pexel && <>Pexel</>}. {storyboard?.videosApi.Pixabay && <>Pixabay</>} </p>}
                {editing ? (



                  <div>
                    <div className='row'>

                      <div className="dropdown col-md-6 mb-2" id="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Items
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          {values.map((value, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${value}Checkbox`}
                                checked={selectedItems[value]}
                                // disabled={value == "category" ? true : false}
                                onChange={() => {
                                  setError(null);
                                  handleCheckboxChange(value);
                                }}
                              />
                              <label className="form-check-label" htmlFor={`${value}Checkbox`}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </label>
                            </div>
                          ))}

                        </div>
                      </div>

                      <div className="dropdown col-6 mb-2" id="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Libraries
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          {videosApi.map((value, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${value}Checkbox`}
                                checked={VideosApiSelection[value]}
                                onChange={() => {
                                  setError(null);
                                  handlevideoApiChange(value)
                                }}
                              />
                              <label className="form-check-label" htmlFor={`${value}Checkbox`}>
                                {value}
                              </label>
                            </div>
                          ))}

                        </div>

                      </div>
                    </div>
                    <textarea spellcheck="true"
                      value={editedData}
                      onChange={handleTextareaChange}
                      style={{ width: '100%', height: '400px', marginBottom: '10px' }}
                    ></textarea>
                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                    <div style={{ textAlign: 'center' }}>

                      <button className="btn btn-primary" onClick={() => { saveEditedData(storyboard._id) }}
                        disabled={error ? true : false}>Save</button>
                      <button className="btn btn-secondary ml-2" onClick={toggleEditing}>Cancel</button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {storyboard.storyboard?.category &&
                      <p><b>LLM-Category: {storyboard.storyboard?.category}</b></p>}
                    {storyboard?.visualPaths?.map((visualPath, index) => (
                      <VisualPathComponent key={index} visualPath={visualPath} index={index} />
                    ))}
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                      <button className="btn btn-primary" onClick={toggleEditing}>Edit</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </>
  );
};

export default EditModal;