// import "./Prompt.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import VersionModal from "./reuse/VersionModal";

function Prompt() {
  const [sbprompt, setsbprompt] = useState("");
  const [rcprompt, setrcprompt] = useState("");
  const [rcprompt_ex, setrcpromptEx] = useState("");
  const [$narration$, set$narration$] = useState("");
  const [$visuals$, set$visuals$] = useState("");
  const [$keywords$, set$keywords$] = useState("");
  const [sbversion, setSbVersion] = useState([]);
  const [rcversion, setRcVersion] = useState([]);
  const [prompstore, setPromptStore] = useState({});
  const BASE_URL = process.env.REACT_APP_API_KEY;

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedRCVersion, setSelectedRCVersion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRCModal, setShowRCModal] = useState(false);
  const [relevanceParam, setRelevanceParam] = useState({
    Visual: true,
    Narration: false,
  });

  const handlevRelevnceParam = (itemName) => {
    console.log(itemName);
    setRelevanceParam((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName]
    }));
  };
  const handleSelectChange = (event) => {
    const version = sbversion.find(v => v.version === event.target.value);
    setSelectedVersion(version);
    setShowModal(true);
  };

  const handleRelevancyChange = (event) => {
    console.log("hbj")
    const version = rcversion.find(v => v.version === event.target.value);
    console.log(version);
    setSelectedRCVersion(version);
    setShowRCModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRCCloseModal = () => {
    console.log("close")
    setShowRCModal(false);
  };
  const values = Object.keys(relevanceParam);

  useEffect(() => {


    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await Axios.get(`${BASE_URL}/api/prompt`);
      // console.log(response.data);
      const { sb_prompt_value, rc_prompt_value, rc_prompt_ex_value, $narration$_value, $visuals$_value, $keywords$_value, sbPrompt_version, rcprompt_sp_version } = response.data;
      // console.log(sbPrompt_version)
      setPromptStore(response.data);

      if (sbPrompt_version, rcprompt_sp_version) {
        setRcVersion(rcprompt_sp_version);
        setSbVersion(sbPrompt_version);
      }
      // Update state only if prompts are not null
      if (sb_prompt_value !== null && rc_prompt_value !== null && rc_prompt_ex_value !== null && $narration$_value !== null && $visuals$_value !== null && $keywords$_value !== null) {
        setsbprompt(sb_prompt_value);
        setrcprompt(rc_prompt_value);
        setrcpromptEx(rc_prompt_ex_value);
        set$narration$($narration$_value);
        set$visuals$($visuals$_value);
        set$keywords$($keywords$_value);
      }
    } catch (error) {
      console.log("error occured", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if either sbprompt or rcprompt is empty
    if (sbprompt.trim() === '' || rcprompt.trim() === '' || rcprompt_ex.trim() === '') {
      alert('Please type both prompts before submitting.');
      return;
    }


    try {
      const params = {
        sb_prompt_value: sbprompt,
        rc_prompt_value: rcprompt,
        rc_prompt_ex_value: rcprompt_ex,
        $narration$_value: $narration$,
        $visuals$_value: $visuals$,
        $keywords$_value: $keywords$,
        relevance_param_value: relevanceParam
      };
      const response = await Axios.post(`${BASE_URL}/api/insert`, params);
      if (response.status == 200) {
        toast.success("Prompts updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })
        setsbprompt(response.data.sb_prompt_value);
        setrcprompt(response.data.rc_prompt_value);
        setrcpromptEx(response.data.rc_prompt_ex_value);
        set$narration$(response.data.$narration$_value);
        set$visuals$(response.data.$visuals$_value);
        set$keywords$(response.data.$keywords$_value);
      }
      else {

        toast.error("Error updating Relevancy prompt");
      }



    } catch (error) {
      console.error("Error:", error);
    }
  };

  function changleSPPrompt() {
    setsbprompt(prompstore?.sbPrompt_static);
  }
  function changeRcPromptSp() {
    setrcprompt(prompstore?.rc_prompt_statci);
  }
  function changeRcPromptEx() {
    setrcpromptEx(prompstore?.rc_prompt_ex_static);
  }

  async function updateSbPrompt() {
    try {
      const response = await Axios.put(`${BASE_URL}/api/sbupdate`, { sbprompt: sbprompt });
      console.log(response);
      if (response.status == 200) {
        toast.success("Story-board prompt updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        await fetchData();
      }
      else {
        toast.error("Error updating story-board prompt");
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function updateRCPrompt() {
    try {
      const response = await Axios.put(`${BASE_URL}/api/rcupdate`, { rcprompt: rcprompt });
      console.log(response);
      if (response.status == 200) {
        toast.success("Relevancy prompt updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        await fetchData();
      }
      else {
        toast.error("Error updating Relevancy prompt");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function updateDynamicVariable() {
    try {


      const response = await Axios.put(`${BASE_URL}/api/dynamicval`, { dynamicVal_1: $narration$, dynamicVal_2: $visuals$, dynamicVal_3: $keywords$ });
      if (response.status == 200) {
        toast.success("Relevancy prompt updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }
      else {
        toast.error("Error updating Relevancy prompt");
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="container-fluid m-3 mt-5">
      <div className="row">
        <div className="alert alert-info mt-3 col-md-6">
          <p><b>Note:</b> The following variables are used for dynamic varaibles:</p>
          <ul>
            <li><b>$dynamicVariable_1$</b>: Dynamic Variable 1</li>
            <li><b>$dynamicVariable_2$</b>:  Dynamic Variable 2</li>
            <li><b>$dynamicVariable_3$</b>:  Dynamic Variable 3</li>
          </ul>
        </div>
        <div className="alert alert-warning mt-3 col-md-6">
          <p><b>Note:</b> The following variables are used for dynamic content</p>
          <ul>
            <li><b>$visuals$</b>: Dynamic visual will be passed to the LLM</li>
            <li><b>$narrations$</b>: Dynamic narration will be passed to the LLM</li>
            <li><b>$keywords$</b>: Dynamic keywords will be passed to the LLM</li>

          </ul>
        </div>
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>

          <div className="">
            <div className="row">
              <div className="col-md-6">
                <h6>Storyboard Versions</h6>
                <select className="form-control mb-3 rounded" onChange={handleSelectChange}>
                  <option value="">Storyboard versions</option>
                  {sbversion && sbversion.map((version) => (
                    <option key={version.version} value={version.version}>
                      {version.version}
                    </option>
                  ))}
                </select>

              </div>
              <div className="col-md-6">
                <h6>Relevancy Versions</h6>
                <select className="form-control mb-3 rounded" onChange={handleRelevancyChange}>
                  <option value="">Relavency versions</option>
                  {rcversion && rcversion.map((version) => (
                    <option key={version.version} value={version.version} >
                      {version.version}
                    </option>
                  ))}
                </select>

              </div>
            </div>

            {selectedVersion && <VersionModal
              show={showModal}
              handleClose={handleCloseModal}
              version={selectedVersion}
              type="sbprompt"
              fetchData={fetchData}
            />}
          </div>
          <div className="row">
            <div className="col-md-6">
              <p><b>Storyboard Generation Prompt</b></p>
              <textarea
                value={sbprompt}
                onChange={(e) => setsbprompt(e.target.value)}
                placeholder="Type your storyboard generation prompt here..."
                rows={24}

                spellCheck={false}
              />

              <button type="button" className="btn btn-warning" onClick={() => { changleSPPrompt() }}
              >Reset-Storyboard</button>
              <button type="button" className="btn btn-success" style={{ marginLeft: '10px' }} onClick={() => { updateSbPrompt() }}>Update</button>
              <div style={{ border: '2px solid #ccc', borderRadius: '5%', padding: '20px', margin: '20px 0' }}>
                <p><b>$dynamicVariable_1$</b></p>
                <textarea
                  value={$narration$}
                  onChange={(e) => set$narration$(e.target.value)}
                  placeholder="Enter the narration description here..."
                  rows={5}

                  spellCheck={false}
                />
                <p><b>$dynamicVariable_2$</b></p>
                <textarea
                  value={$visuals$}
                  onChange={(e) => set$visuals$(e.target.value)}
                  placeholder="Enter visual description here..."
                  rows={5}

                  spellCheck={false}
                />
                <p><b>$dynamicVariable_3$</b></p>
                <textarea
                  value={$keywords$}
                  onChange={(e) => set$keywords$(e.target.value)}
                  placeholder="Enter keyword description here..."
                  rows={5}

                  spellCheck={false}
                />
                <button type="button" className="btn btn-success" style={{ marginLeft: '10px' }} onClick={() => { updateDynamicVariable() }}>Update </button>
              </div>
            </div>
            <div className="col-md-6">


              <div className="">

                {/* <div className="col-md-4">
                    <h6>Relevancy Versions</h6>
                    <select className="form-control mb-3 rounded" onChange={handleRelevancyChange}>
                      <option value="">Relavency versions</option>
                      {rcversion && rcversion.map((version) => (
                        <option key={version.version} value={version.version} >
                          {version.version}
                        </option>
                      ))}
                    </select>

                  </div> */}


                {selectedRCVersion && <VersionModal
                  show={showRCModal}
                  handleClose={handleRCCloseModal}
                  version={selectedRCVersion}
                  type="rcprompt_sp"
                  fetchData={fetchData}

                />}
              </div>


              <p><b>Relavency Checker Prompt</b></p>

              <textarea
                value={rcprompt}
                onChange={(e) =>
                  setrcprompt(e.target.value)

                }
                placeholder="Type your relavency checker prompt here..."
                rows={24}

                spellCheck={false}
              />
              <button type="button" className="btn btn-warning" onClick={() => { changeRcPromptSp() }}>Reset Relevancy</button>
              <button type="button" className="btn btn-success" style={{ marginLeft: '10px' }} onClick={() => { updateRCPrompt() }}>Update </button>
              <br />
              {/* <p><b>Example-Relevance</b></p>
              <textarea
                value={rcprompt_ex}
                onChange={(e) => setrcpromptEx(e.target.value)}
                placeholder="Type your relavency checker prompt here..."
                rows={15}
               
                spellCheck={false}
              />
              <button type="button" className="btn btn-warning" onClick={() => { changeRcPromptEx() }}>Reset_RC_Ex</button> */}

            </div>
          </div>
          {/* <button type="submit" className="btn btn-success">Update</button> */}
        </form>
      </div>
    </div>

  );
}

export default Prompt;
